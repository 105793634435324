:root {
  --sidebar-bg: #e8f4f8;
  --main-bg: #e8f4f8;
}

$theme-colors: (
  'primary': #00b9bd,
  'secondary': #19282d,
  'grey': #c5c4c4,
  'white': #ffffff,
  'black': #000000
);

.bg-dark-gray {
  background: #1c343d;
}

@import '~bootstrap/scss/bootstrap';
@import './auth/login.scss';

.cursor-pointer {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
}

.active-pagination {
  background-color: #00b9bd;
  color: white;
}

.icon-cont {
  width: 40px;
  display: block;
  text-align: center;
  flex-shrink: 0;
}

#navbar-main {
  background-color: theme-color('secondary');
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: 55px;
  padding: 0 !important;
  display: flex;
  align-items: center;

  img {
    height: 15px;
  }
}

#sidenav-main {
  width: 220px !important;
  top: 55px;
  position: fixed;
  height: calc(100vh - 55px);
  background-color: var(--sidebar-bg);
  border-right: 1px solid rgba(255, 255, 255, 0.8);
  transition: 0.5s;
  z-index: 999;

  .nav-link {
    span {
      opacity: 1;
    }

    .nav-link-icon-active {
      display: none !important;
    }

    &:hover,
    &.active {
      color: theme-color('white') !important;
      background-color: theme-color('primary');

      .nav-link-icon {
        display: none;
      }

      .nav-link-icon-active {
        display: block !important;
      }
    }
  }

  .sidenav-footer {
    position: absolute;
    background-color: transparent;
    bottom: 0;
    //  left: 0px;
    transform: translate(50%, 10%);
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    transition: all 0.2s linear;
  }

  .sidenav-footer > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

#sidenav-child {
  width: 210px !important;
  top: 55px;
  position: fixed;
  left: 0px;
  height: calc(100vh - 55px);
  background-color: theme-color('white');
  visibility: hidden;
  z-index: 998;
  transition: visibility 0s, left 0.5s;

  .nav-item {
    border-bottom: 0 !important;
    cursor: pointer;
    font-size: 14px !important;
    color: theme-color('black') !important;

    .nav-link {
      height: 35px !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
      font-size: 16px !important;
      color: theme-color('dark') !important;

      .nav-link-icon-active {
        display: none !important;
      }

      &:hover,
      &.active {
        .nav-link-icon {
          display: none;
        }

        .nav-link-icon-active {
          display: block !important;
        }

        color: theme-color('primary') !important;
        background-color: theme-color('white');
      }
    }

    &.main {
      .nav-link {
        font-weight: 500;
        font-size: 20px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        height: 55px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-left: 0 !important;
        margin-right: 0 !important;

        &:hover,
        &.active {
          color: theme-color('dark') !important;
        }
      }
    }
  }

  .icon-cont {
    width: 35px;
  }
}

#main-content {
  width: calc(100% - 220px);
  margin-left: 220px;
  overflow-y: auto;
  height: calc(100vh - 55px);
  margin-top: 55px;
  background-color: var(--main-bg) !important;
  transition: 0.5s;
}

.nav-item {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  color: theme-color('black') !important;
  font-size: 14px;

  span {
    display: block;
  }

  .nav-link {
    height: 40px;
    display: flex;
    align-items: center;
    color: theme-color('black') !important;
  }

  &:hover,
  &.active {
    color: theme-color('white') !important;
    background-color: theme-color('primary');

    .nav-link {
      color: theme-color('white') !important;
    }
  }
}

.submenu-opened {
  #main-content {
    width: calc(100% - 430px);
    margin-left: 430px;
  }

  #sidenav-child {
    left: 220px;
    visibility: visible;
  }
}

.sidebar-collapsed {
  #sidenav-main {
    width: 40px !important;

    .nav-item {
      span {
        display: none;
        transition: 0.5s;
        transition-delay: 0.5s;
      }
    }
  }

  #main-content {
    width: calc(100% - 40px);
    margin-left: 40px;
  }

  .sidenav-footer {
    visibility: hidden;
    opacity: 0;
    //   transition: visibility 2s linear 100ms, opacity 100ms;
  }
}

.rotate {
  transition: 0.5s;

  &-180 {
    transform: rotate(180deg);
  }
}

@media (max-width: 991.98px) {
  #main-content {
    width: calc(100% - 40px) !important;
    margin-left: 40px !important;
  }
}

@media (max-width: 479.98px) {
  #sidenav-main,
  #sidenav-child {
    width: 185px !important;
  }

  .submenu-opened {
    #sidenav-child {
      left: 185px !important;
    }
  }

  .nav-item {
    font-size: 12px;
  }
}

.circle {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  background-color: #d2d6de;

  &.active {
    background-color: theme-color('primary') !important;
  }

  &--sm {
    width: 16px !important;
    height: 16px !important;
  }
}

.article {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &__table {
    margin-left: 80px;
    width: calc(100% - 80px);

    td {
      vertical-align: middle;
    }
  }

  &__img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  &:last-child {
    border-bottom: none;
  }
}

.image-preview {
  width: 90px;
  height: 90px;
  padding: 10px;
  object-fit: contain;
  margin: 0 auto;
}

.input-settings {
  border: 1px solid #adb5bd !important;
  border-radius: 5px;
}

.promotion__table {
  min-width: 992px !important;
}

.group-name {
  border-bottom: 1.5px dashed rgba(0, 0, 0, 0.15);
  padding-bottom: 5px;
  min-width: 210px;
}

.group-name--subitem {
  min-width: 60px !important;
}

.d-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 30px;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.custom-check-input {
  width: 25px;
  height: 25px;
}

.custom-color-input {
  border: 0 !important;
  width: 26px;
  height: 30px;
  padding: 0 !important;
  background: transparent;
}

.cursor {
  cursor: pointer;
}

.gradient-green {
  font-size: 72px;
  background: -webkit-linear-gradient(#73d383, #3ad3c1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.euro {
  font-size: 50px;
}

.CircularProgressbar-text {
  transform: translate(-15px, 6px) !important;
}

@media only screen and (max-width: 600px) {
  .w-xs-100 {
    width: 100% !important;
  }
}

.inputs-group__articlemodal {
  width: 450px;
}
.form-div{
  position: relative;
}
.overlay-fade {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(189, 185, 185, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  border-radius: 10px;
}
.btn-print-select{
  background: #eee;
  box-shadow: 0 8px 8px -4px lightblue;
}
.btn-print{
  border-radius: 5px;
  box-shadow: 3px 3px 5px 6px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.btn-print span{
font-size: 14px;
}
.tbl-print{
  height: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 2px #ccc;
    display: align-items-center;
    display: flex;
    align-items: center;
}

.tbl-select-print{
  height: 40px;
  display: align-items-center;
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 3px lightblue;
 /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}