.NewsButton {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: red;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 9px;
}
.RemoveButton {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  /* color: red; */
  /* height: 20px;
    width: 20px;
    position: absolute;
    left:9px; */
}
