.card {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .floor-card-header {
    background-color: #1e323a;
    color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .floor-card-body {
    background-color: #ecf0f1;
    height: 200px;
    overflow-y: scroll;
    .dish-extra {
      font-size: 10px;
    }
  }
  .line-bottom {
    height: 1px;
    background-color: black;
  }
  .butn {
    height: 45px;
    width: 200px;
    color: #fff;
    border: none;
    border-radius: 10px;
  }
  .del-btn {
    background-color: #e12b46;
  }
  .success-btn {
    background-color: #039d96;
  }
}
